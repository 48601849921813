@font-face {
	font-family: 'Konnect Medium';
	font-style: normal;
	font-weight: normal;
	src: local('Konnect Medium'),
		url('./assets/fonts/KonnectMedium.woff') format('woff');
}

body {
	font-family: 'Konnect Medium';
	font-weight: normal;
	font-style: normal;
}
